<template>
    <div>
         <!-- 生成图片的按钮 -->
         <!-- <button @click="generateImage">生成图片</button> -->
        <!-- 展示生成的图片 -->
        <img  :src="imageUrl" class="ShareImg" :style="{'height':windowHeight+'px'}" v-if="imageUrl" alt="生成的图片">
        <!-- 要转换为图片的HTML元素 -->
        <div ref="targetElement" class="posterShareBg" :style="{'height':windowHeight+'px'}" v-else>
            <p class="text"><span class="name">{{ userInfo.nickName }}</span>邀请好友下单，可得<span class="price">500元</span>代金券！</p>
            <img :src="qrcodeUrl" alt="" class="qrCode">
        </div>
      
    </div>
  </template>
  
  <script>
  import html2canvas from 'html2canvas';
  import { getQrCode } from '@/api/index'
  
  export default {
    data() {
      return {
        imageUrl: "",
        qrcodeUrl:"",
        windowHeight: window.innerHeight,
        userInfo:""
      };
    },
    created(){
        this.getQrCode()
    },
    methods: {
        getQrCode(){
          this.$store.commit("loadingControl",true)
          // this.qrcodeUrl = 'https://lsdj-resource.lanshididu.com/ums-qrcode%2F100%2F20250218%2F8c16d795d6f944b287e463189f6aefda.png'
            let userInfo = JSON.parse(localStorage.getItem('userInfo'))
            console.log(userInfo)
            this.userInfo = userInfo
            getQrCode({
                userId: userInfo.id
            }).then(res => {
                this.qrcodeUrl = res.data
                setTimeout(() => {
                    this.generateImage()
                }, 300)
            })
        },
      async generateImage() {
        try {
          // 获取要转换的HTML元素
          const targetElement = this.$refs.targetElement;
          let options = {
            useCORS:true, // 使用跨域
            ignoreElements:false,
            scale:6, //dpi 设置默认值，提高图片分辨率
        };
          // 使用html2canvas将元素转换为canvas
          const canvas = await html2canvas(targetElement, options);
          // 将canvas转换为图片的Base64编码
          const dataURL = canvas.toDataURL('image/png');
          // 将Base64编码赋值给imageUrl，用于展示图片
          this.imageUrl = dataURL;
          this.$store.commit("loadingControl",false)
        } catch (error) {
          console.error('生成图片时出错:', error);
          this.$store.commit("loadingControl",false)
          this.$toast('生成图片失败，请稍后再试')
        }
      }
    }
  };
  </script>
  
  <style scoped lang="less">
  .posterShareBg{
    width: 100%;
    background: url("https://lsdj-resource.lanshididu.com/admin%2F20250302%2F29061740821915_.pic.jpg") no-repeat;
    // background: url("../assets/img/share.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    .text{
      position: absolute;
      height: 1.25rem;
      width: 100%;
      // background-color: red;
      top: 0;
      left: 0;
      color:#2B7BEE;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: .4rem;
      font-size: .35rem;
      font-weight: bold;
      .name{
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .price{
        color: #DE4E2A;
        font-size: .53rem;
        display: inline-block;
        margin-bottom: .1rem;
      }
    }
    .qrCode{
      width: 5.2rem;
      height:5.2rem;
      position: absolute;
      bottom: 3.4rem;
      left: 2.4rem;
    }
  }

  .ShareImg{
    width: 100%;
    height: auto;
  }
  </style>